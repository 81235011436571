import 'slick-carousel/slick/slick';

$('.carousel-atrativos').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        },
    },
    {
        breakpoint: 784,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
});

$('#checkin').on('change', function() {
    let date = $("#checkin").val();
    date = date.replace("-", "");
    let year = date.slice(0, 4);
    let month = date.slice(4, 6);
    let day = date.slice(7, 9);
    date = day + '' + month + '' + year;
    $("#checkinInput").val(date);
});

$('#checkout').on('change', function() {
    let date = $("#checkout").val();
    date = date.replace("-", "");
    let year = date.slice(0, 4);
    let month = date.slice(4, 6);
    let day = date.slice(7, 9);
    date = day + '' + month + '' + year;
    $("#checkoutInput").val(date);
});
